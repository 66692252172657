@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  text-align: right;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  flex: 1;
}

footer {
  text-align: center;
  padding: 10px 0;
  margin-top: auto;
}
